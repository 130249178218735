import {
    Box,
    Card,
    CardContent,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SxProps,
    Theme as SystemTheme,
    Typography,
    useTheme,
} from '@mui/material';
import { BulletIcon } from 'components/BulletIcon';
import { HelmetHeader } from 'components/HelmetHeader';
import { PageBanner } from 'components/PageBanner';
import { sectionSxProps, subSectionSxProps, titleBoxSxPropsSansTheme } from 'shared/styles';
import Banner from '../assets/Pricing.webp';
import ScratchPay from '../assets/Scratch-Pay-consumer-link-Veterinary-250x100_2x.webp';
import { Paths, navItems } from './Routes';

const bodySxProps: SxProps<SystemTheme> = {
    typography: {
        md: 'body1', // 900
        sx: 'body2', // 0
    },
};
export const Pricing = () => {
    const theme = useTheme();

    const subTitleSxProps: SxProps<SystemTheme> = {
        typography: {
            lg: 'h4', // 1200
            md: 'h4', // 900
            sm: 'h5', // 600
            mobile: 'h6', // 320
        },
        color: theme.palette.secondary.main,
    };

    const titleBoxSxProps: SxProps<SystemTheme> = {
        ...titleBoxSxPropsSansTheme,
        background: theme.palette.secondary.light,
    };

    const titleSxProps: SxProps<SystemTheme> = {
        typography: {
            lg: { fontSize: 60, fontFamily: 'Raleway Variable' }, // 1200
            md: { fontSize: 50, fontFamily: 'Raleway Variable' }, // 900
            sm: { fontSize: 40, fontFamily: 'Raleway Variable' }, // 600
            mobile: { fontSize: 30, fontFamily: 'Raleway Variable' }, // 600
            color: theme.palette.secondary.contrastText,
        },
    };

    const title = 'Pricing';
    return (
        <main id="main">
            <HelmetHeader
                title="Memorials"
                description="Transparent pricing for In-Home Euthanasia, Cremation, and Tele-Advice services."
                relPath={Paths.pricing}
                og={{ imageName: 'Passing-logo-01-1200x1200.webp', alt: 'Passing Paws In-Home Pet Euthanasia Logo' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { md: 'center' } }}>
                <PageBanner
                    title={title}
                    bannerURL={Banner}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        maxWidth: 1200,
                        marginTop: 2,
                    }}
                >
                    <Card
                        sx={{ ...sectionSxProps, background: theme.palette.background.default, marginLeft: 4, marginRight: 4 }}
                        variant="outlined"
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    ...titleBoxSxProps,
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...titleSxProps,
                                        textAlign: 'center',
                                    }}
                                    alignSelf={'flex-start'}
                                >
                                    Pet Care Services
                                </Typography>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    In-Home Euthanasia Appointment: $300
                                </Typography>

                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <List aria-label="Generic Appointment Details">
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>
                                                    Includes standard travel fees, medications administered, a peaceful passing, clay paw print, and
                                                    lock of fur.
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>
                                                    If electing to bury your pet, you must abide by your local laws regarding pet burial explained{' '}
                                                    <Link
                                                        color={'text.secondary'}
                                                        href={navItems.find((ni) => ni.path === Paths.faqs)?.path}
                                                    >
                                                        here
                                                    </Link>
                                                    .
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Body Care Appointment: $150
                                </Typography>

                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <List aria-label="Body Care Appointment Details">
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>
                                                    For pets who have already passed away. Includes travel fees, respectful handling of your pet, clay
                                                    paw print, and a lock of fur.
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Tele-Advice: $75
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <List aria-label="Tele-Advice Details">
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>30 minute phone consultation</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Fees
                                </Typography>

                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <List aria-label="In-Home Euthanasia Fees">
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>
                                                    Out-of-hours appointments (after hours, weekends, holidays) are limited and may be available if
                                                    pre-arranged. An additional $100 fee will apply.
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>Extra travel fees may apply to some areas.</Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>Cremation fees are an additional charge.</Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>Large pets may require additional fees.</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>

                    <Card
                        sx={{ ...sectionSxProps, background: theme.palette.background.default, marginLeft: 4, marginRight: 4, alignSelf: 'stretch' }}
                        variant="outlined"
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    ...titleBoxSxProps,
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...titleSxProps,
                                        textAlign: 'center',
                                    }}
                                    alignSelf={'flex-start'}
                                >
                                    Cremation Services
                                </Typography>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Private Cremation
                                </Typography>

                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <List aria-label="Private Cremation Details">
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>
                                                    Your pet is cremated by themselves and the ashes are returned to you.
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>
                                                    Prices are based on your pet's weight. Please{' '}
                                                    <Link
                                                        color={'text.secondary'}
                                                        href={navItems.find((ni) => ni.path === Paths.contact)?.path}
                                                    >
                                                        contact us
                                                    </Link>{' '}
                                                    for an estimate.
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>

                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Communal Cremation
                                </Typography>

                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <List aria-label="Communal Cremation Details">
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText sx={bodySxProps}>
                                                <Typography>
                                                    Your pet is cremated alongside other pets and their ashes are scattered on Animal Care Services'
                                                    memorial grounds.
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>
                                                    Prices are based on your pet's weight. Please{' '}
                                                    <Link
                                                        color={'text.secondary'}
                                                        href={navItems.find((ni) => ni.path === Paths.contact)?.path}
                                                    >
                                                        contact us
                                                    </Link>{' '}
                                                    for an estimate.
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>

                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Fees
                                </Typography>

                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <List aria-label="Cremation Fees">
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>
                                                    If the pet is over 100lbs and you would like Passing Paws to help with aftercare, crematory
                                                    assistance will be required with 24 hours advanced notice. Dr. Shantelle will arrange this for
                                                    you, and it will incur an extra fee.
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>

                    <Card
                        sx={{ ...sectionSxProps, background: theme.palette.background.default, marginLeft: 4, marginRight: 4, alignSelf: 'stretch' }}
                        variant="outlined"
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    ...titleBoxSxProps,
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...titleSxProps,
                                        textAlign: 'center',
                                    }}
                                    alignSelf={'flex-start'}
                                >
                                    Payments
                                </Typography>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <List aria-label="Payment Information">
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>
                                                    Payment is due at or before the time of service. If you need assistance with payment, please apply
                                                    for{' '}
                                                    <Link
                                                        color="text.secondary"
                                                        href="https://scratchpay.com/"
                                                    >
                                                        ScratchPay
                                                    </Link>{' '}
                                                    or{' '}
                                                    <Link
                                                        color="text.secondary"
                                                        href="https://www.affirm.com/shopping/virtual-card"
                                                    >
                                                        Affirm virtual card
                                                    </Link>
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography sx={bodySxProps}>
                                                    An invoice will be sent prior to the appointment that can be paid online or at the start of the
                                                    appointment. We accept all major credit cards, Zelle, Apple Pay, cash, as well as{' '}
                                                    <Link
                                                        color="text.secondary"
                                                        href="https://scratchpay.com/"
                                                    >
                                                        ScratchPay
                                                    </Link>{' '}
                                                    and{' '}
                                                    <Link
                                                        color="text.secondary"
                                                        href="https://www.affirm.com/shopping/virtual-card"
                                                    >
                                                        Affirm virtual card
                                                    </Link>
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                    <Link
                                        href="https://scratchpay.com/"
                                        sx={{ alignSelf: 'center' }}
                                    >
                                        <img
                                            src={ScratchPay}
                                            alt="Scratch Pay plan button"
                                            width="100%"
                                        />
                                    </Link>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </main>
    );
};
