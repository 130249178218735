import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Card, CardContent, Link, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { HelmetHeader } from 'components/HelmetHeader';
import { PageBanner } from 'components/PageBanner';
import React from 'react';
import { containerSxProps } from 'shared/styles';
import Banner from '../assets/FAQs.webp';
import { Paths, navItems } from './Routes';

interface faqProps {
    title: string;
    component?: JSX.Element;
    expanded: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export const FAQS = () => {
    const theme = useTheme();
    const secondaryTextColor = useTheme().palette.text.secondary;

    const faqs: faqProps[] = [
        {
            title: 'Should my children be present?',
            component: (
                <Typography>
                    The loss of a pet is often a child’s first experience with death. It is important to have open communication and be honest with
                    children who are old enough to take part in the conversation. It is also an opportunity to teach them about your beliefs in the
                    afterlife. We typically recommend asking your child if they would prefer to be present or not at the appointment. It can also be
                    helpful to include your child in making memorial items, such as paw prints, or participate in bucket list activities. For very
                    young children who cannot understand death yet, they may be concerned about the family’s emotions/reactions and may take the
                    family’s focus away from your pet. You ultimately know your child best and can determine what is appropriate for them. Dr.
                    Shantelle is very patient and kind when helping children understand the euthanasia process.
                </Typography>
            ),
            expanded: React.useState<boolean>(true),
        },
        {
            title: 'Should my other pets be present?',
            component: (
                <Typography>
                    Most pets do seem to understand when their housemate is ill and nearing death. They may distance themselves or may cling to their
                    housemate. They also seem to understand that a pet is gone once they have passed away. If the housemate is rowdy, it may be
                    appropriate for them to be outside or in another room during the appointment to provide your pet a calm atmosphere. We always
                    recommend bringing them out afterwards to see their housemate’s body. Some pets may not want to approach the body at all, and some
                    pets will sniff it and then walk away. Pets do grieve but seem to have a healthier relationship with death than we do. They
                    require lots of love and attention if they are grieving. If the grieving is extreme, it may be necessary to speak with their
                    regular veterinarian about temporary treatments that may help.
                </Typography>
            ),
            expanded: React.useState<boolean>(true),
        },
        {
            title: 'How will I know it’s time?',
            component: (
                <Typography>
                    Please visit our{' '}
                    <Link
                        color={secondaryTextColor}
                        href={`/${Paths.time}`}
                    >
                        {navItems.find((i) => i.path === Paths.time)?.title}
                    </Link>{' '}
                    page.
                </Typography>
            ),
            expanded: React.useState<boolean>(true),
        },
        {
            title: 'What types of payment do you take?',
            component: (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography gutterBottom>
                        We accept all major credit/debit cards, Zelle, Apple Pay, and cash as well as these payment plans:{' '}
                        <Link
                            color={secondaryTextColor}
                            href="https://scratchpay.com/"
                        >
                            ScratchPay
                        </Link>{' '}
                        and{' '}
                        <Link
                            color={secondaryTextColor}
                            href="https://www.affirm.com/shopping/virtual-card"
                        >
                            Affirm virtual card
                        </Link>
                        .
                    </Typography>
                    <Typography>Payment is due before the appointment starts to allow us to focus directly on your pet.</Typography>
                </Box>
            ),
            expanded: React.useState<boolean>(true),
        },
        {
            title: 'What are the local laws and regulations on burial?',
            component: (
                <Typography>
                    In Missouri, animals must be buried within 24 hours of death. Burial sites shall not be in an area prone to flooding and the hole
                    should be no more than 6 feet deep. The animals should be covered with a minimum of thirty inches (2.5 feet) of soil. The burial
                    site should be at least 50 feet from the property line, at least 300 feet from the neighbor’s residence, and more than 300 feet
                    from any body of water such as a stream, lake, pond, well, etc. We live in an area with major groundwater contamination potential.
                    If you choose burial for your aftercare, it is important to follow these regulations.
                </Typography>
            ),
            expanded: React.useState<boolean>(true),
        },
    ];

    const title = 'Frequently Asked Questions';
    return (
        <main id="main">
            <HelmetHeader
                title={title}
                description="Answers to frequently asked questions of concerned pet owners."
                relPath={Paths.faqs}
                og={{ imageName: 'Passing-logo-01-1200x1200.webp', alt: 'Passing Paws In-Home Pet Euthanasia Logo' }}
            />
            <Box sx={{ ...containerSxProps }}>
                <PageBanner
                    title={title}
                    bannerURL={Banner}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        maxWidth: 1200,
                        marginTop: 2,
                    }}
                >
                    <Card sx={{ background: theme.palette.background.default, marginLeft: 4, marginRight: 4 }}>
                        <CardContent>
                            {faqs.map((f) => (
                                <Accordion
                                    key={f.title}
                                    expanded={f.expanded[0]}
                                    onChange={() => f.expanded[1](!f.expanded[0])}
                                    sx={{
                                        mb: 4,
                                    }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-label={f.title + ' Click to view more'}
                                    >
                                        <Typography
                                            sx={{
                                                typography: {
                                                    lg: 'h5', // 1200
                                                    md: 'h5', // 900
                                                    sm: 'h6', // 600
                                                    mobile: 'body1', // 320
                                                    xs: 'body1', // 0
                                                },
                                                color: theme.palette.secondary.main,
                                            }}
                                        >
                                            {f.title}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ color: theme.palette.text.primary }}>{f.component}</AccordionDetails>
                                </Accordion>
                            ))}
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </main>
    );
};
