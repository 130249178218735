import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Box, Card, CardContent, Link, SxProps, Theme as SystemTheme, Typography, useTheme } from '@mui/material';
import { HelmetHeader } from 'components/HelmetHeader';
import { PageBanner } from 'components/PageBanner';
import { email, emergencyReferrals, phone } from 'shared/resources';
import ContactUsBanner from '../assets/contact-us-banner-1920x680.webp';
import { Paths, navItems } from './Routes';

const contactCards: SxProps<SystemTheme> = {
    display: 'flex',
    flexDirection: 'column',
    margin: 2,
    width: { xs: 'stretch', md: 350 },
    maxWidth: 350,
    justifyContent: 'center',
};

const contactHeader: SxProps<SystemTheme> = {
    marginBottom: 2,
};
const title = 'Contact Us';
export const ContactUs = () => {
    const theme = useTheme();
    return (
        <main id="main">
            <HelmetHeader
                title={title}
                description="Call, Text, or Email us to make an In-Home Euthanasia or Body Care appointment."
                relPath={Paths.contact}
                og={{ imageName: 'Passing-logo-01-1200x1200.webp', alt: 'Passing Paws In-Home Pet Euthanasia Logo' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <PageBanner
                    title={title}
                    bannerURL={ContactUsBanner}
                />
                {/*                 <Typography
                    variant={'h4'}
                    sx={{ textAlign: 'center', margin: 8 }}
                >
                    We will be CLOSED 07/24 - 07/30. Sorry for any inconvenience.
                </Typography> */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { lg: 'row', xs: 'column' },
                        justifyContent: 'center',
                        alignItems: { lg: 'normal', xs: 'center' },
                        textAlign: 'center',
                        marginTop: 2,
                        marginBottom: 2,
                    }}
                >
                    <Card sx={{ ...contactCards }}>
                        <Box sx={{ alignSelf: 'center' }}>
                            <CallIcon
                                sx={{
                                    color: theme.palette.secondary.main,
                                    fontSize: 80,
                                }}
                            />
                        </Box>

                        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                variant="h5"
                                sx={{ ...contactHeader }}
                            >
                                Call Or Text
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ marginBottom: 1 }}
                            >
                                <Link
                                    color={theme.palette.text.secondary}
                                    href={`Tel:${phone}`}
                                >
                                    {phone}
                                </Link>
                            </Typography>
                            <Typography variant="body2">
                                You will get a prompt response if you text or email. If you leave a voicemail, please allow up to 24 hours to receive
                                a response. You may also request an{' '}
                                <Link
                                    color={theme.palette.text.secondary}
                                    href={navItems.find((item) => item.path === Paths.appointmentFullUrl)?.path}
                                >
                                    appointment
                                </Link>{' '}
                                online.
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ ...contactCards }}>
                        <Box sx={{ alignSelf: 'center' }}>
                            <EmailIcon
                                sx={{
                                    color: theme.palette.secondary.main,
                                    fontSize: 80,
                                }}
                            />
                        </Box>

                        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                variant="h5"
                                sx={{ ...contactHeader }}
                            >
                                Email Us
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ marginBottom: 1 }}
                            >
                                <Link
                                    color={theme.palette.text.secondary}
                                    href={`mailto:${email}`}
                                >
                                    {email}
                                </Link>
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ ...contactCards }}>
                        <Box sx={{ alignSelf: 'center' }}>
                            <ScheduleIcon
                                sx={{
                                    color: theme.palette.secondary.main,
                                    fontSize: 80,
                                }}
                            />
                        </Box>

                        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                variant="h5"
                                sx={{ ...contactHeader }}
                            >
                                Hours
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ marginBottom: 1 }}
                            >
                                Mon-Fri 9am-5pm
                            </Typography>
                        </CardContent>
                    </Card>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', marginBottom: 4, marginLeft: 2, marginRight: 2 }}>
                    <Typography marginBottom={2}>
                        There are no guarantees that same day appointments will be available. We request as much advanced notice as possible.
                    </Typography>
                    <Typography>
                        If this is an emergency, please contact{' '}
                        {emergencyReferrals.map(
                            (er, index) =>
                                er.name +
                                ' at ' +
                                er.phone +
                                (emergencyReferrals.length > 2 && index < emergencyReferrals.length - 2
                                    ? ', '
                                    : index === emergencyReferrals.length - 2
                                    ? ' or '
                                    : ''),
                        )}{' '}
                        for assistance.
                    </Typography>
                </Box>
                <Box sx={{ width: { xs: 280, sm: 480, md: 640 }, height: { xs: 210, sm: 360, md: 480 }, alignSelf: 'center' }}>
                    <iframe
                        title="Passing Paws Service Area Map"
                        src="https://www.google.com/maps/d/u/0/embed?mid=1V4hvlr0a0nuMs6AvtsX-l4oLy3-aH_M&ehbc=2E312F&noprof=1"
                        width="100%"
                        height="100%"
                        aria-label="Map of Passing Paws Service Area"
                    ></iframe>
                    <Typography sx={{ textAlign: 'center' }}>Servicing St. Charles County and parts of Lincoln and Warren County.</Typography>
                </Box>
            </Box>
        </main>
    );
};
