import { Box, Button, Card, CardContent, ImageList, ImageListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { HelmetHeader } from 'components/HelmetHeader';
import { PageBanner } from 'components/PageBanner';
import { memorialsAndTestimonialsLink, testimonialsData } from 'shared/resources';
import testimonialBanner from '../assets/father-with-son-walk-with-beagle-dog-and-enjoy-warm-autumn-day.webp';
import { Paths } from './Routes';

export const Testimonials = () => {
    const theme = useTheme();
    const threeColumns = useMediaQuery(theme.breakpoints.up('threeColumns'));
    const twoColumns = useMediaQuery(theme.breakpoints.up('twoColumns'));

    const title = 'Testimonials';
    return (
        <main id="main">
            <HelmetHeader
                title={title}
                description="See what other pet owners have to say about their experience with Passing Paws In-Home Pet Euthanasia."
                relPath={Paths.testimonials}
                og={{ imageName: 'Passing-logo-01-1200x1200.webp', alt: 'Passing Paws In-Home Pet Euthanasia Logo' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <PageBanner
                    bannerURL={testimonialBanner}
                    content={
                        <Box sx={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                sx={{
                                    typography: {
                                        lg: { fontSize: 110, fontFamily: 'Raleway Variable' }, // 1200
                                        md: { fontSize: 84, fontFamily: 'Raleway Variable' }, // 900
                                        sm: { fontSize: 56, fontFamily: 'Raleway Variable' }, // 600
                                        mobile: { fontSize: 44, fontFamily: 'Raleway Variable' }, // 320
                                        xs: { fontSize: 30, fontFamily: 'Raleway Variable' }, // 0
                                    },
                                    textShadow: '2px 2px 0px #000000',
                                    textAlign: 'center',
                                }}
                                color={'white'}
                            >
                                {title}
                            </Typography>
                            <Typography
                                sx={{
                                    typography: {
                                        lg: { fontSize: 21 }, // 1200
                                        md: { fontSize: 15 }, // 900
                                        sm: { fontSize: 12 }, // 600
                                        mobile: { fontSize: 12 }, // 320
                                        xs: { fontSize: 12 }, // 0
                                    },
                                    textShadow: '2px 2px 0px #000000',
                                    textAlign: 'center',
                                    alignSelf: 'center',
                                    justifySelf: 'center',
                                    maxWidth: '50%',
                                }}
                                color={'white'}
                            >
                                See what other pet owners have to say about their experience with Passing Paws.
                            </Typography>

                            <Button
                                href={memorialsAndTestimonialsLink}
                                sx={{
                                    marginTop: { sm: 6, xs: 1 },
                                    textAlign: 'center',
                                    alignSelf: 'center',
                                }}
                                variant="contained"
                                color="secondary"
                            >
                                Submit Your Own Testimonial
                            </Button>
                        </Box>
                    }
                />

                <Box sx={{ maxWidth: 1200, alignSelf: 'center' }}>
                    <ImageList
                        variant="masonry"
                        cols={threeColumns ? 3 : twoColumns ? 2 : 1}
                        gap={8}
                    >
                        {testimonialsData.map((item) => (
                            <ImageListItem
                                key={item.name}
                                sx={{ paddingBottom: 1, paddingTop: 1 }}
                            >
                                <Card sx={{ width: 320, marginLeft: 1, marginRight: 1 }}>
                                    <CardContent>
                                        {item.texts.map((text, index) => (
                                            <Typography
                                                gutterBottom
                                                variant="body2"
                                                color="text.primary"
                                                fontStyle={'italic'}
                                            >
                                                {index === 0 && item.texts.length === 1
                                                    ? '"' + text + '"'
                                                    : index === 0
                                                    ? '"' + text
                                                    : index === item.texts.length - 1
                                                    ? text + '"'
                                                    : text}
                                            </Typography>
                                        ))}
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            color="text.secondary"
                                        >
                                            {'-' + item.name}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            </Box>
        </main>
    );
};
