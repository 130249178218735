import { Box, Card, CardContent, SxProps, Theme as SystemTheme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { AppointmentButton } from 'components/AppointmentButton';
import { ContainedBannerButton } from 'components/ContainedBannerButton';
import { HelmetHeader } from 'components/HelmetHeader';
import { PageBanner } from 'components/PageBanner';
import { sectionSxProps, subSectionSxProps, titleBoxSxPropsSansTheme } from 'shared/styles';
import PricingBanner from '../assets/Pricing.webp';
import Banner from '../assets/Services.webp';
import { Paths } from './Routes';

const subTitleSxProps: SxProps<SystemTheme> = {
    typography: {
        lg: 'h4', // 1200
        md: 'h4', // 900
        sm: 'h5', // 600
        mobile: 'h6', // 320
    },
};

const bodySxProps: SxProps<SystemTheme> = {
    typography: {
        sm: 'body1', // 600
        mobile: 'body2', // 320
    },
};

export const Services = () => {
    const theme = useTheme();
    const wideEnough = useMediaQuery(theme.breakpoints.up('topBar'));
    const bottomButtonsData: { path: string; image: string; alt: string }[] = [
        { path: Paths.pricing, image: PricingBanner, alt: 'Sleeping cat, who is over this' },
    ];

    const titleBoxSxProps: SxProps<SystemTheme> = {
        ...titleBoxSxPropsSansTheme,
        background: theme.palette.secondary.light,
    };

    const titleSxProps: SxProps<SystemTheme> = {
        typography: {
            lg: { fontSize: 60, fontFamily: 'Raleway Variable' }, // 1200
            md: { fontSize: 50, fontFamily: 'Raleway Variable' }, // 900
            sm: { fontSize: 40, fontFamily: 'Raleway Variable' }, // 600
            mobile: { fontSize: 30, fontFamily: 'Raleway Variable' }, // 600
            color: theme.palette.secondary.contrastText,
        },
    };
    const title = 'Services';
    return (
        <main id="main">
            <HelmetHeader
                title={title}
                description="Service descriptions for In-Home Euthanasia, Tele-Advice, and Body Care."
                relPath={Paths.services}
                og={{ imageName: 'Passing-logo-01-1200x1200.webp', alt: 'Passing Paws In-Home Pet Euthanasia Logo' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { md: 'center' } }}>
                <PageBanner
                    title={title}
                    bannerURL={Banner}
                    alt="Dog waiting, paws draped over a fence"
                />

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        maxWidth: 1200,
                        marginTop: 2,
                        marginBottom: 2,
                    }}
                >
                    <Card
                        sx={{
                            ...sectionSxProps,
                            background: theme.palette.background.default,
                            marginLeft: 4,
                            marginRight: 4,
                            display: 'flex',
                        }}
                        variant="outlined"
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    ...titleBoxSxProps,
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...titleSxProps,
                                        textAlign: 'center',
                                    }}
                                    alignSelf={'flex-start'}
                                >
                                    In-Home Euthanasia
                                </Typography>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Why In-Home Euthanasia?
                                </Typography>
                                <Typography sx={{ ...bodySxProps }}>
                                    At Passing Paws we will ensure that your pet’s passing is as peaceful as possible. Many pets are painful at the
                                    end of life and experience fear and anxiety going to the veterinary hospital. In-Home Euthanasia takes that fear
                                    away from your pet and allows your family to grieve in the comfort of your own home. Euthanasia is the final gift
                                    that a family can give to their pet, and it should be a serene experience.
                                </Typography>
                            </Box>

                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    What to Expect
                                </Typography>
                                <Typography sx={{ ...bodySxProps }}>
                                    Your pet and family will be treated with kindness and compassion throughout the entire process. Dr. Shantelle will
                                    give you a call or text when she is on her way to your home with an estimated time of arrival. She will meet your
                                    pet and family and allow time for your pet to become comfortable with her. She will briefly discuss what has been
                                    going on with your pet to confirm that euthanasia is the right thing before proceeding. Then all paperwork and
                                    aftercare arrangements will be taken care of so your family can focus only on your pet.
                                </Typography>
                            </Box>

                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography sx={{ ...bodySxProps }}>
                                    After everyone is settled and all questions are answered, Dr. Shantelle will go through the euthanasia process in
                                    detail and what to expect after your pet passes away. She uses a two-step process. First, a sedative/pain
                                    medication is administered, and your pet is allowed to relax. Dr. Shantelle will confirm that your pet is fully
                                    relaxed and unable to feel anything. She will then help your pet pass away with a second medication. Your pet will
                                    pass away peacefully and without any pain.
                                </Typography>
                            </Box>

                            <Box>
                                <Typography sx={{ ...bodySxProps }}>
                                    Once Dr. Shantelle has confirmed that your pet has passed, she will make a clay paw print and obtain a lock of fur
                                    if your family wishes. She will then step outside to allow your family to grieve in private. If you would like her
                                    to help with your pet’s aftercare, she will bring in a basket or a stretcher (depending on the size of your pet)
                                    with a comfortable blanket. She may need assistance getting your pet to the car if they are over 35lbs. Your pet
                                    will be transported to the crematory where they will be respectfully handled.
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>

                    <Card
                        sx={{ ...sectionSxProps, background: theme.palette.background.default, marginLeft: 4, marginRight: 4 }}
                        variant="outlined"
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    ...titleBoxSxProps,
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...titleSxProps,
                                        textAlign: 'center',
                                    }}
                                    alignSelf={'flex-start'}
                                >
                                    Tele-Advice
                                </Typography>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography sx={{ ...bodySxProps }}>
                                    If you are not sure if your pet is ready for euthanasia or need help making the decision, you can schedule a
                                    tele-advice phone call. Dr. Shantelle will go over your pet’s ailments and how they affect their quality of life.
                                    She will help you determine if euthanasia may be the kindest option for your pet or if there may be more that can
                                    be done. Dr. Shantelle cannot diagnose or treat conditions over the phone.
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>

                    <Card
                        sx={{ ...sectionSxProps, background: theme.palette.background.default, marginLeft: 4, marginRight: 4 }}
                        variant="outlined"
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    ...titleBoxSxProps,
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...titleSxProps,
                                        textAlign: 'center',
                                    }}
                                    alignSelf={'flex-start'}
                                >
                                    Body Care
                                </Typography>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography sx={{ ...bodySxProps }}>
                                    If your pet passes away at home, we will come to pick your pet up and transport them to the crematory for
                                    whichever aftercare option you would prefer. This includes a paw print and fur clipping.
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                    {wideEnough ? null : (
                        <Box sx={{ marginBottom: 4, alignSelf: 'center' }}>
                            <AppointmentButton width={240} />
                        </Box>
                    )}
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignSelf: 'center' }}>
                        {bottomButtonsData.map((item) => (
                            <ContainedBannerButton
                                image={item.image}
                                path={item.path}
                                key={item.path}
                                alt={item.alt}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>
        </main>
    );
};
