import { Box, Button, Card, CardContent, ImageList, ImageListItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CarouselImage } from 'components/CarouselImage';
import { HelmetHeader } from 'components/HelmetHeader';
import { PageBanner } from 'components/PageBanner';
import Carousel from 'react-material-ui-carousel';
import { memorialsAndTestimonialsLink, memorialsData } from 'shared/resources';
import CatSleeping from '../assets/cat-sleeping-banner.webp';
import { Paths } from './Routes';

export const Memorials = () => {
    const theme = useTheme();
    const threeColumns = useMediaQuery(theme.breakpoints.up('threeColumns'));
    const twoColumns = useMediaQuery(theme.breakpoints.up('twoColumns'));
    return (
        <main id="main">
            <HelmetHeader
                title="Memorials"
                description="Memorials to keep the beloved memories of our pets alive."
                relPath={Paths.memorials}
                og={{ imageName: 'Passing-logo-01-1200x1200.webp', alt: 'Passing Paws In-Home Pet Euthanasia Logo' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <PageBanner
                    bannerURL={CatSleeping}
                    content={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography
                                sx={{
                                    typography: {
                                        lg: { fontSize: 110, fontFamily: 'Raleway Variable' }, // 1200
                                        md: { fontSize: 84, fontFamily: 'Raleway Variable' }, // 900
                                        sm: { fontSize: 56, fontFamily: 'Raleway Variable' }, // 600
                                        mobile: { fontSize: 44, fontFamily: 'Raleway Variable' }, // 320
                                        xs: { fontSize: 30, fontFamily: 'Raleway Variable' }, // 0
                                    },
                                    textShadow: '2px 2px 0px #000000',
                                    textAlign: 'center',
                                }}
                                color={'white'}
                            >
                                In Memorium
                            </Typography>
                            <Typography
                                sx={{
                                    typography: {
                                        lg: { fontSize: 21 }, // 1200
                                        md: { fontSize: 15 }, // 900
                                        sm: { fontSize: 12 }, // 600
                                        mobile: { fontSize: 12 }, // 320
                                        xs: { fontSize: 12 }, // 0
                                    },
                                    textShadow: '2px 2px 0px #000000',
                                    textAlign: 'center',
                                    alignSelf: 'center',
                                    justifySelf: 'center',
                                    maxWidth: '50%',
                                }}
                                color={'white'}
                            >
                                Our beloved friends will live on forever in our hearts.
                            </Typography>

                            <Button
                                href={memorialsAndTestimonialsLink}
                                sx={{
                                    marginTop: { sm: 6, xs: 1 },
                                    textAlign: 'center',
                                    alignSelf: 'center',
                                }}
                                variant="contained"
                                color="secondary"
                            >
                                Submit Your Own Memorial
                            </Button>
                        </Box>
                    }
                />

                <Box sx={{ maxWidth: 1200, alignSelf: 'center' }}>
                    <ImageList
                        variant="masonry"
                        cols={threeColumns ? 3 : twoColumns ? 2 : 1}
                        gap={8}
                    >
                        {memorialsData.map((item) => (
                            <ImageListItem
                                key={item.images[0]}
                                sx={{ paddingBottom: 1, paddingTop: 1 }}
                            >
                                <Card sx={{ width: 320, marginLeft: 1, marginRight: 1 }}>
                                    <Carousel
                                        indicators={item.images.length > 1}
                                        navButtonsAlwaysInvisible={item.images.length === 1}
                                    >
                                        {item.images.map((image, i) => (
                                            <CarouselImage
                                                key={i}
                                                image={image}
                                                title={item.images.length > 1 ? item.name + '_' + (i + 1) : item.name}
                                            />
                                        ))}
                                    </Carousel>
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                            color="text.secondary"
                                        >
                                            {item.name}
                                        </Typography>
                                        {item.texts.map((text, index) => (
                                            <Typography
                                                gutterBottom
                                                variant="body2"
                                                color="text.primary"
                                                fontStyle={'italic'}
                                            >
                                                {index === 0 && item.texts.length === 1
                                                    ? '"' + text + '"'
                                                    : index === 0
                                                    ? '"' + text
                                                    : index === item.texts.length - 1
                                                    ? text + '"'
                                                    : text}
                                            </Typography>
                                        ))}
                                    </CardContent>
                                </Card>
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
            </Box>
        </main>
    );
};
