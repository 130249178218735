import { Box, Button, Link, Skeleton, Typography, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import { websiteName, websiteNameShort } from 'App';
import { HelmetHeader } from 'components/HelmetHeader';
import { useState } from 'react';
import { ResourceId, Resources } from 'shared/resources';
import CPEV from '../assets/CPEVLogo.webp';
import DrShantelle from '../assets/DrShantelle.webp';
import { Paths, navItems } from './Routes';

export const About = () => {
    const [imageLoaded, setImageLoadedState] = useState(false);
    const [certLoaded, setCertLoadedState] = useState(false);
    const theme = useTheme();
    const secondaryTextColor = theme.palette.text.secondary;
    const title = 'About Us';
    return (
        <main id="main">
            <HelmetHeader
                title={title}
                description="Learn about the Passing Paws team!"
                relPath={Paths.about}
                og={{ imageName: 'Passing-logo-01-1200x1200.webp', alt: 'Passing Paws In-Home Pet Euthanasia Logo' }}
            />
            <Box sx={{ display: 'flex', mr: 2, ml: 2, mt: 2, pr: 2, flexDirection: 'column', justifyContent: 'center' }}>
                <Box sx={{ display: 'flex', mr: 2, ml: 2, pr: 2, flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'center' }}>
                    <Box sx={{ alignSelf: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <img
                                height={496}
                                width={279}
                                style={{ borderRadius: 8, marginBottom: 8, display: imageLoaded ? 'block' : 'none' }}
                                alt={'Dr. Shantelle Williams and her dog Petra'}
                                title={'Dr. Shantelle Williams and her dog Petra'}
                                src={DrShantelle}
                                onLoad={() => setImageLoadedState(true)}
                            />
                            {!imageLoaded && (
                                <Skeleton
                                    height={496}
                                    width={279}
                                    variant="rectangular"
                                    animation="wave"
                                    style={{ borderRadius: 8, marginBottom: 8 }}
                                />
                            )}
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            alignSelf: { xs: 'center', md: 'flex-start' },
                            maxWidth: 'sm',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            ml: { md: 5 },
                        }}
                    >
                        <Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography
                                    variant="h1"
                                    sx={{ typography: { sm: 'h4', xs: 'h5' }, color: theme.palette.secondary.main }}
                                    component="h1"
                                >
                                    Dr. Shantelle Williams, CPEV
                                </Typography>
                            </Box>
                            <Divider sx={{ marginBottom: 2, borderWidth: 3, borderRadius: 100 }} />
                        </Box>

                        <Typography
                            gutterBottom
                            sx={{ mb: 2, typography: { xs: 'body2', sm: 'body1' } }}
                        >
                            Dr. Shantelle graduated from the University of Missouri College of Veterinary Medicine in 2017 and is CPEV (Certified
                            Peaceful Euthanasia Veterinarian) certified by the{' '}
                            <Link
                                color={secondaryTextColor}
                                href={Resources.find((r) => r.id === ResourceId.CAETA)?.url}
                            >
                                CAETA
                            </Link>{' '}
                            (Companion Animal Euthanasia Training Academy) organization. She worked at a general veterinary practice in the St. Louis
                            area for 3 years before moving to the Tampa, FL area to practice exclusively in-home euthanasia and palliative care for 4
                            years. She then moved to the Wentzville area in 2024 when she decided to start {websiteName}.
                        </Typography>
                        <Typography
                            gutterBottom
                            sx={{ mb: 4, typography: { xs: 'body2', sm: 'body1' } }}
                        >
                            Dr. Shantelle established {websiteNameShort} to focus on ensuring that families have access to a compassionate and gentle
                            goodbye in the comfort of their own home. She has a mission of honoring the human-animal bond and its incredibly important
                            place in our lives. The difference that in-home euthanasia makes in the lives of pets and their families drives her to
                            commit to giving families the most peaceful experience possible.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: 'center',
                                marginBottom: 2,
                                alignItems: { xs: 'center', md: 'flex-start' },
                            }}
                        >
                            {navItems
                                .filter((i) => [Paths.time, Paths.services, Paths.contact].includes(i.path))
                                .map((item) => (
                                    <Button
                                        key={item.path}
                                        sx={{
                                            marginRight: { xs: 0, md: 4 },
                                            marginBottom: { xs: 4, md: 0 },
                                            whiteSpace: 'nowrap',
                                            textAlign: 'center',
                                        }}
                                        href={`/${item.path}`}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        {item.title}
                                    </Button>
                                ))}
                        </Box>
                    </Box>
                </Box>
                <Divider
                    sx={{ alignSelf: { xs: 'center', mobile: 'stretch' } }}
                    component={'div'}
                    role="presentation"
                >
                    <Button
                        href={Resources.find((r) => r.id === ResourceId.CAETA)?.url}
                        sx={{ padding: 0, margin: 0 }}
                    >
                        <Box>
                            <img
                                height={200}
                                alt={'CPEV (Certified Peaceful Euthanasia) Certification Logo'}
                                title={'CPEV Certification Logo'}
                                src={CPEV}
                                style={{ alignSelf: 'center', display: imageLoaded ? 'block' : 'none' }}
                                onLoad={() => setCertLoadedState(true)}
                            />

                            {!certLoaded && (
                                <Skeleton
                                    height={200}
                                    variant="rectangular"
                                    animation="wave"
                                    sx={{ aspectRatio: '384/364', alignSelf: 'center' }}
                                />
                            )}
                        </Box>
                    </Button>
                </Divider>
            </Box>
        </main>
    );
};
