import {
    Box,
    Card,
    CardContent,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SxProps,
    Theme as SystemTheme,
    Typography,
    useTheme,
} from '@mui/material';
import { BulletIcon } from 'components/BulletIcon';
import { HelmetHeader } from 'components/HelmetHeader';
import { PageBanner } from 'components/PageBanner';
import {
    adultBooks,
    childrensBooks,
    localPetLossResources,
    missouriCrisisResources,
    nationalSuicidePreventionResources,
    petLossSupportLines,
    weeklyChatRooms,
} from 'shared/resources';
import { sectionSxProps, subSectionSxProps, titleBoxSxPropsSansTheme } from 'shared/styles';
import Banner from '../assets/Resources1.webp';
import { Paths } from './Routes';

export const Resources = () => {
    const theme = useTheme();
    const secondaryTextColor = theme.palette.text.secondary;

    const titleBoxSxProps: SxProps<SystemTheme> = {
        ...titleBoxSxPropsSansTheme,
        background: theme.palette.secondary.light,
    };
    const subTitleSxProps: SxProps<SystemTheme> = {
        typography: {
            lg: 'h4', // 1200
            md: 'h4', // 900
            sm: 'h5', // 600
            mobile: 'h6', // 320
        },
        color: theme.palette.secondary.main,
    };

    const titleSxProps: SxProps<SystemTheme> = {
        typography: {
            lg: { fontSize: 60, fontFamily: 'Raleway Variable' }, // 1200
            md: { fontSize: 50, fontFamily: 'Raleway Variable' }, // 900
            sm: { fontSize: 36, fontFamily: 'Raleway Variable' }, // 600
            mobile: { fontSize: 26, fontFamily: 'Raleway Variable' }, // 320
            color: theme.palette.secondary.contrastText,
        },
    };
    const title = 'Resources';
    return (
        <main id="main">
            <HelmetHeader
                title={title}
                description="Pet Loss resources for families facing their pet's end of life journey."
                relPath={Paths.resources}
                og={{ imageName: 'Passing-logo-01-1200x1200.webp', alt: 'Passing Paws In-Home Pet Euthanasia Logo' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'flex-start', md: 'center' } }}>
                <PageBanner
                    title={title}
                    bannerURL={Banner}
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        maxWidth: 1200,
                        width: '100%',
                        marginTop: 2,
                        marginBottom: 2,
                    }}
                >
                    <Card
                        sx={{ ...sectionSxProps, background: theme.palette.background.default, marginLeft: 4, marginRight: 4 }}
                        variant="outlined"
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    ...titleBoxSxProps,
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...titleSxProps,
                                        textAlign: 'center',
                                    }}
                                >
                                    Recommended Books
                                </Typography>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Books and Journals for Adults
                                </Typography>
                                <List aria-label="List of books for adults">
                                    {adultBooks.map((ab) => (
                                        <ListItem key={ab.title}>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography
                                                    fontStyle={'italic'}
                                                    component={'span'}
                                                >
                                                    {ab.title}
                                                </Typography>
                                                <Typography component={'span'}>&nbsp;</Typography>
                                                <Typography component={'span'}>by {ab.author}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>

                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Children’s Books
                                </Typography>
                                <List aria-label="List of books for children">
                                    {childrensBooks.map((cb) => (
                                        <ListItem key={cb.title}>
                                            <ListItemIcon>
                                                <BulletIcon theme={theme} />
                                            </ListItemIcon>
                                            <ListItemText>
                                                <Typography
                                                    fontStyle={'italic'}
                                                    component={'span'}
                                                >
                                                    {cb.title}
                                                </Typography>
                                                <Typography component={'span'}>&nbsp;</Typography>
                                                <Typography component={'span'}>by {cb.author}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>
                        </CardContent>
                    </Card>

                    <Card
                        sx={{ ...sectionSxProps, background: theme.palette.background.default, marginLeft: 4, marginRight: 4 }}
                        variant="outlined"
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    ...titleBoxSxProps,
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...titleSxProps,
                                        textAlign: 'center',
                                    }}
                                    alignSelf={'flex-start'}
                                >
                                    Local Pet Loss Resources
                                </Typography>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                {localPetLossResources.map((r) => (
                                    <ListItem key={r.href}>
                                        <ListItemIcon>
                                            <BulletIcon theme={theme} />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography>
                                                {r.preText}{' '}
                                                <Link
                                                    color={secondaryTextColor}
                                                    href={r.href}
                                                >
                                                    {r.linkText}
                                                </Link>
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </Box>
                        </CardContent>
                    </Card>

                    <Card
                        sx={{ ...sectionSxProps, background: theme.palette.background.default, marginLeft: 4, marginRight: 4 }}
                        variant="outlined"
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    ...titleBoxSxProps,
                                }}
                            >
                                <Typography
                                    sx={{
                                        ...titleSxProps,
                                        textAlign: 'center',
                                    }}
                                    alignSelf={'flex-start'}
                                >
                                    Other Recommended Resources
                                </Typography>
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    National Suicide Prevention Lifeline
                                </Typography>
                                {nationalSuicidePreventionResources.map((r) => (
                                    <ListItem key={r.href}>
                                        <ListItemIcon>
                                            <BulletIcon theme={theme} />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography>
                                                {r.preText}{' '}
                                                <Link
                                                    color={secondaryTextColor}
                                                    href={r.href}
                                                >
                                                    {r.linkText}
                                                </Link>
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Missouri Crisis Text Line
                                </Typography>
                                {missouriCrisisResources.map((r) => (
                                    <ListItem key={r.href}>
                                        <ListItemIcon>
                                            <BulletIcon theme={theme} />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography>
                                                {r.preText}{' '}
                                                <Link
                                                    color={secondaryTextColor}
                                                    href={r.href}
                                                >
                                                    {r.linkText}
                                                </Link>
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Pet Loss Support Lines/Chat
                                </Typography>
                                {petLossSupportLines.map((r) => (
                                    <ListItem key={r.href}>
                                        <ListItemIcon>
                                            <BulletIcon theme={theme} />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography>
                                                {r.preText}{' '}
                                                <Link
                                                    color={secondaryTextColor}
                                                    href={r.href}
                                                >
                                                    {r.linkText}
                                                </Link>
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </Box>
                            <Box sx={{ ...subSectionSxProps }}>
                                <Typography
                                    sx={{ ...subTitleSxProps }}
                                    gutterBottom
                                >
                                    Weekly Chat Rooms
                                </Typography>
                                {weeklyChatRooms.map((r) => (
                                    <ListItem key={r.href}>
                                        <ListItemIcon>
                                            <BulletIcon theme={theme} />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography>
                                                {r.preText}{' '}
                                                <Link
                                                    color={secondaryTextColor}
                                                    href={r.href}
                                                >
                                                    {r.linkText}
                                                </Link>
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </Box>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </main>
    );
};
