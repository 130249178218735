import { Box, Skeleton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { websiteName } from 'App';
import { AppointmentButton } from 'components/AppointmentButton';
import { ContainedBannerButton } from 'components/ContainedBannerButton';
import { HelmetHeader } from 'components/HelmetHeader';
import { useState } from 'react';
import HDIKITBanner from '../assets/How-Do-I-Know-Its-Time-Banner.webp';
import ServicesBanner from '../assets/Services.webp';
import ContactBanner from '../assets/contact-us-banner-180h.webp';
import NoPlaceLikeHomeVideo from '../assets/no-place-like-homev.webm';
import { Paths } from './Routes';

export const Home = () => {
    const [videoLoaded, setVideoLoadedState] = useState(false);
    const bottomButtonsData: { path: string; image: string }[] = [
        { path: Paths.time, image: HDIKITBanner },
        { path: Paths.services, image: ServicesBanner },
        { path: Paths.contact, image: ContactBanner },
    ];
    const theme = useTheme();
    const wideEnough = useMediaQuery(theme.breakpoints.up('topBar'));
    return (
        <main id="main">
            <HelmetHeader
                title={websiteName}
                description={websiteName + 'offers end of life and cremation services to St. Charles County and parts of Lincoln and Warren County.'}
                og={{ imageName: 'Passing-logo-01-1200x1200.webp', alt: 'Passing Paws In-Home Pet Euthanasia Logo' }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                    sx={{
                        width: {
                            lg: '100%', // 1200
                            md: '115%', // 900
                            sm: '130%', // 600
                            mobile: '160%', // 320
                            xs: '180%', // 0
                        },
                        alignSelf: 'center',
                        flexDirection: 'row',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            width: {
                                lg: '100%', // 1200
                                md: '85%', // 900
                                sm: '76%', // 600
                                mobile: '62%', // 320
                                xs: '53%', // 0
                            },
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={{
                                typography: {
                                    lg: { fontSize: 110, fontFamily: 'Raleway Variable' }, // 1200
                                    md: { fontSize: 84, fontFamily: 'Raleway Variable' }, // 900
                                    sm: { fontSize: 60, fontFamily: 'Raleway Variable' }, // 600
                                    mobile: { fontSize: 44, fontFamily: 'Raleway Variable' }, // 320
                                    xs: { fontSize: 30, fontFamily: 'Raleway Variable' }, // 0
                                },
                                textShadow: '2px 2px 0px #000000',
                                textAlign: 'center',
                            }}
                            color={'white'}
                            component="h1"
                        >
                            In-Home Pet Euthanasia
                        </Typography>

                        <Typography
                            sx={{
                                typography: {
                                    lg: { fontSize: 60, fontFamily: 'Raleway Variable' },
                                    md: { fontSize: 50, fontFamily: 'Raleway Variable' },
                                    sm: { fontSize: 33, fontFamily: 'Raleway Variable' },
                                    mobile: { fontSize: 20, fontFamily: 'Raleway Variable' },
                                    xs: { fontSize: 16, fontFamily: 'Raleway Variable' },
                                },
                                fontFamily: 'Raleway Variable',
                                textShadow: '2px 2px 0px #000000',
                                textAlign: 'center',
                            }}
                            color={'white'}
                        >
                            Because there's no place like home
                        </Typography>
                    </Box>

                    <Box style={{ display: videoLoaded ? 'block' : 'none' }}>
                        <video
                            width={'100%'}
                            height={'auto'}
                            autoPlay
                            loop
                            playsInline
                            muted
                            aria-label="Video of a lady and her dog cuddling on a cozy couch."
                            style={{ alignSelf: 'stretch' }}
                            onLoadedData={() => setVideoLoadedState(true)}
                        >
                            <source
                                src={NoPlaceLikeHomeVideo}
                                type="video/webm"
                                style={{ aspectRatio: '1280 / 640' }}
                            />
                        </video>
                    </Box>

                    {!videoLoaded && (
                        <Skeleton
                            variant="rectangular"
                            width={'100%'}
                            height={'auto'}
                            animation="wave"
                            sx={{ alignSelf: 'stretch', aspectRatio: '1280 / 640', bgcolor: 'grey.500' }}
                        />
                    )}
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignSelf: 'center',
                        marginTop: '10vh',
                        marginBottom: '10vh',
                        marginLeft: 4,
                        marginRight: 4,
                        maxWidth: 1000,
                    }}
                >
                    <Typography
                        sx={{ typography: { xs: 'h6', sm: 'h5', md: 'h4' }, color: theme.palette.secondary.main }}
                        marginBottom={4}
                        textAlign={'center'}
                    >
                        In-Home Pet Euthanasia Servicing St. Charles County and parts of Lincoln and Warren County
                    </Typography>
                    <Typography sx={{ typography: { xs: 'body2', sm: 'body1', md: 'h6' }, textAlign: 'center' }}>
                        When it comes to saying goodbye to our precious pets, there is nothing we want more than a peaceful and dignified transition.
                        Dr. Shantelle has been exclusively providing compassionate euthanasia for thousands of pets at home since 2020 and knows
                        exactly how to make the process as gentle as possible.
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignSelf: 'center' }}>
                    {bottomButtonsData.map((item) => (
                        <ContainedBannerButton
                            image={item.image}
                            path={item.path}
                            key={item.path}
                        />
                    ))}
                </Box>
                {wideEnough ? null : (
                    <AppointmentButton
                        width={240}
                        alignSelf="center"
                    />
                )}
            </Box>
        </main>
    );
};
